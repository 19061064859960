<template>
  <div class="fortune-wheel-form">
    <HeadSection
      :title="currentWheelId ? `Item ID ${currentWheelId}` : 'Create fortune wheel'"
      :breadcrumbs="breadcrumbs"
      permissionOfSection="fortune_wheel"
    >
      <template #actions v-if="!isOnlyView">
        <a-col :span="14" class="d-flex justify-content-end">
          <div class="d-flex">
            <a-popconfirm
              placement="top"
              :disabled="loading"
              title="Are you sure you want to delete this wheel?"
              @confirm="() => deleteWheel()"
            >
              <a-button
                v-if="deletePermission && currentWheelId"
                :class="{'mr-3' : deletePermission && currentWheelId}"
                type="danger"
                size="large"
              >
                Delete
              </a-button>
            </a-popconfirm>
            <a-button
              v-if="editPermission"
              type="primary"
              size="large"
              @click="save"
            >
              {{ currentWheelId ? 'Save' : 'Create' }}
            </a-button>
          </div>
        </a-col>
      </template>
    </HeadSection>
    <MainContainer :loading="userLoading" permissionOfSection="fortune_wheel">
      <span class="d-block mb-3 font-weight-bold font-size-20">General info</span>
      <a-form-model :model="form" :rules="rules" ref="form">
        <a-row>
          <a-col>
            <a-form-model-item label="Inner wheel name" prop="name" :colon="false" style="max-width: 270px">
              <a-input
                v-model="form.name"
                placeholder="Wheel name"
                size="large"
                :disabled="loading || isOnlyView"
              />
            </a-form-model-item>
          </a-col>
          <a-row class="d-flex align-items-center">
            <a-form-model-item label="Start date and time" prop="starts_at" :colon="false" style="max-width: 270px">
              <a-date-picker
                v-model="form.starts_at"
                size="large"
                format="YYYY-MM-DD HH:mm"
                :disabled="loading || isOnlyView"
                :disabled-date="disableDatesBeforeToday"
                :show-time="{
                  defaultValue: $moment('00:00:00', 'HH:mm'),
                  format: 'HH:mm'
                }"
                class="hover-date-picker"
              />
            </a-form-model-item>
            <span class="mx-3">—</span>
            <a-form-model-item label="End date and time" prop="ends_at" :colon="false" style="max-width: 270px">
              <a-date-picker
                v-model="form.ends_at"
                size="large"
                format="YYYY-MM-DD HH:mm"
                :disabled="loading || isOnlyView"
                :disabled-date="disabledEndDate"
                :show-time="{
                  defaultValue: $moment('00:00:00', 'HH:mm'),
                  format: 'HH:mm'
                }"
                class="hover-date-picker"
              />
            </a-form-model-item>
          </a-row>
        </a-row>
        <span class="d-block mb-3 font-weight-bold font-size-20">Apps and Segment</span>
        <a-row>
          <a-form-model-item prop="applications" required :colon="false" class="d-block">
            <template #label>
              <span class="mr-4">Apps</span>
              <span v-if="applicationOptions.length && !isOnlyView" class="add-link cursor-pointer" @click.stop="toggleAppModal(true)">+ Add</span>
            </template>
            <div v-if="form.applications">
              <a-tag
                v-for="app in form.applications.slice(0, 5)"
                :key="app.id"
                style="
                  margin-right: 15px;
                  padding: 1px 8px;
                  border-radius: 2px;
                "
                class="cursor-pointer"
                @click="toggleAppModal(true)"
              >
                {{ app.name }}
                <a-icon v-if="app?.platform?.toLowerCase() === 'android'" type="android" class="ml-1 text-success" />
                <a-icon v-if="app?.platform?.toLowerCase() === 'ios'" type="apple" class="ml-1 text-primary" />
                <a-icon v-if="app?.platform?.toLowerCase() === 'web'" type="global" class="ml-1 text-default" />
              </a-tag>
              <a-tag v-if="form.applications.length > 4"> ... </a-tag>
            </div>
          </a-form-model-item>
        </a-row>
        <a-row class="d-flex">
          <div class="d-flex flex-column">
            <span class="font-weight-bold font-size-16">Segment</span>
            <a-form-model-item prop="is_auth_only" :colon="false" class="mb-0">
              <a-checkbox
                v-model="form.is_auth_only"
                :disabled="loading || !!currentWheelId"
              >
                For Authorized users
              </a-checkbox>
            </a-form-model-item>
            <div class="d-flex mb-3" style="gap: 10px;">
              <div class="mt-1">
                <a-icon class="d-flex" :class="{ 'text-default' : currentWheelId }" type="info-circle" />
              </div>
              <span
                class="d-flex"
                :class="{ 'text-default' : currentWheelId }"
                style="max-width: 300px"
              >
                Checkbox state can only be set during wheel creation and can't be changed later
              </span>
            </div>
          </div>
          <a-form-model-item
            v-if="form.is_auth_only"
            prop="segment_id"
            class="label-with-tooltip"
            style="margin-top: 30px;"
            :colon="false"
          >
            <template #label>
              <div>
                <span class="mr-2 mb-0 font-weight-bold" style="line-height: initial;">Select segment</span>
                <a-tooltip
                  placement="top"
                  arrow-point-at-center
                  :overlayStyle="{ 'white-space': 'normal', 'min-width': '240px' }"
                  :get-popup-container="getPopupContainer"
                >
                  <template slot="title">
                    <span class="font-weight-light">To create and edit segments, navigate to the <router-link to="/segments" class="text-primary" target="_blank">"Segments section"</router-link></span>
                  </template>
                  <a-icon class="d-flex" type="info-circle" />
                </a-tooltip>
              </div>
            </template>
            <UniversalSelect
              v-model="form.segment_id"
              style="width: 270px;"
              :options="segmentsOptions"
              :showSearch="true"
              placeholder="Select segment"
              :disabled="loading || isOnlyView"
            />
          </a-form-model-item>
        </a-row>
        <span class="d-block mb-3 font-weight-bold font-size-20">Details</span>
        <a-row>
          <a-form-model-item prop="languages" required :colon="false" class="d-block">
            <template #label>
              <span class="mr-4">Languages</span>
              <span v-if="!isOnlyView" class="add-link cursor-pointer" @click.stop="toggleLanguageModal(true)">+ Add</span>
            </template>
            <div class="fortune-wheel-form__languages">
              <div
                v-for="(language, index) in form.languages"
                :key="language.language_id"
                class="fortune-wheel-form__language"
                :class="{'cursor-pointer' : !isOnlyView}"
              >
                <div>
                  <span>{{ getLanguageName(language.language_id) }}</span>
                  <a-badge
                    v-if="language.is_default"
                    count="Default"
                    :number-style="{
                      backgroundColor: '#fff',
                      color: '#999',
                      boxShadow: '0 0 0 1px #d9d9d9 inset',
                    }"
                  />
                </div>
                <div v-if="!isOnlyView">
                  <span
                    v-if="!language.is_default"
                    class="fortune-wheel-form__language__set-default text-primary"
                    @click="changeDefaultLanguage(index)"
                  >
                    Set as default
                  </span>
                  <a-icon class="font-size-18 text-primary" type="edit" @click="editLanguage(language)" />
                  <a-popconfirm
                    placement="left"
                    :disabled="loading"
                    title="Are you sure you want to delete this language?"
                    @confirm="() => deleteLanguage(language, index)"
                  >
                    <a-icon class="font-size-18 text-danger delete-icon" type="delete" />
                  </a-popconfirm>
                </div>
                <div v-else>
                  <a-icon class="font-size-18 text-orange" type="eye" @click="editLanguage(language)" />
                </div>
              </div>
            </div>
          </a-form-model-item>
        </a-row>
        <a-row>
          <a-form-model-item prop="show_frequency" label="Display Frequency (days)" :colon="false" class="d-block">
            <a-input
              v-model="form.show_frequency"
              style="width: 200px"
              placeholder="Display Frequency"
              size="large"
              :disabled="loading || isOnlyView"
              @keypress="(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault()
                }
              }"
            />
          </a-form-model-item>
          <a-form-model-item prop="display_type" label="Display type" :colon="false">
            <UniversalSelect
              v-model="form.display_type"
              :displayOptionsAtBottom="true"
              :options="displayTypeOptions"
              :allowClear="false"
              placeholder="Select type"
              style="width: 200px;"
              :disabled="loading || isOnlyView"
            />
          </a-form-model-item>
        </a-row>
      </a-form-model>
    </MainContainer>
    <a-modal
      v-model="appsModal"
      title="Add new apps"
      :footer="null"
      width="700px"
      :destroyOnClose="true"
    >
      <AppsModal
        :modalAppsData="modalAppsData"
        :availableApplications="applicationOptions"
        :readonly="isOnlyView"
        @selectApps="changeSelectedApps"
        @close="toggleAppModal(false)"
      />
    </a-modal>
    <a-modal
      v-model="languageModal"
      :title="selectedLanguage ? 'Edit language' : 'Add new language'"
      :footer="null"
      width="500px"
      :destroyOnClose="true"
      @cancel="toggleLanguageModal(false)"
    >
      <LanguageModal
        :selectedLanguages="form.languages"
        :selectedLanguage="selectedLanguage"
        :languageOptions="languageOptions"
        :readonly="isOnlyView"
        :withSearch="true"
        @save="saveLanguage"
        @close="toggleLanguageModal(false)"
      />
    </a-modal>
  </div>
</template>

<script>
import HeadSection from '@/components/main/headSection'
import MainContainer from '@/components/main/mainContainer'
import UniversalSelect from '@/components/custom/filters/universalSelect.vue'
import apiClient from '@/services/axios'
import AppsModal from '@/components/custom/modals/appsModal.vue'
import LanguageModal from '@/components/custom/modals/languageModal.vue'
import { mapState } from 'vuex'
import { getErrorMessage } from '@/views/apps/fortune-wheel/composables/useGetErrorMessage.js'

const wheelApiUrl = process.env.VUE_APP_WHEEL_API_URL

export default {
  props: {
    createPermission: {
      type: Boolean,
      default: false,
    },
    editPermission: {
      type: Boolean,
      default: false,
    },
    deletePermission: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    HeadSection,
    MainContainer,
    UniversalSelect,
    AppsModal,
    LanguageModal,
  },
  data: () => ({
    currentWheelId: null,
    currentWheel: null,
    segmentsOptions: [],
    applicationOptions: [],
    languageOptions: [],
    modalAppsData: null,
    form: {
      name: '',
      starts_at: undefined,
      ends_at: undefined,
      is_auth_only: false,
      segment_id: undefined,
      applications: [],
      languages: [],
      show_frequency: undefined,
      display_type: undefined,
    },
    displayTypeOptions: [
      { name: 'Instant popup', value: 'instant_popup' },
      { name: 'FAB', value: 'fab' },
    ],
    appsModal: false,
    languageModal: false,
    selectedLanguage: null,
    loading: false,
  }),
  computed: {
    ...mapState(['user']),
    userLoading() {
      return this.$store.state?.user?.loading || false
    },
    isOnlyView() {
      return this.$route.name === 'view-current-fortune-wheel'
    },
    breadcrumbs() {
      if (this.currentWheelId) {
        return [
          { name: 'Fortune wheel', link: '/fortune-wheel' },
          { name: this.form.name, link: `/fortune-wheel/${this.currentWheelId}` },
          { name: this.isOnlyView ? 'View' : 'Edit', link: undefined },
        ]
      }
      return [
        { name: 'Fortune wheel', link: '/fortune-wheel' },
        { name: 'Create fortune wheel', link: undefined },
      ]
    },
    rules() {
      const currentRules = {
        name: [{ required: true, message: 'Wheel name is required', trigger: ['blur', 'change'] }],
        applications: [{ type: 'array', required: true, message: 'Please select at least one app', trigger: 'change' }],
        languages: [{ type: 'array', required: true, message: 'Please select at least one language', trigger: 'change' }],
        show_frequency: [{ required: true, message: 'Display frequency value is required', trigger: ['blur', 'change'] }],
        display_type: [{ required: true, message: 'Display type is required', trigger: ['blur', 'change'] }],
      }
      if (this.form.is_auth_only) {
        currentRules.segment_id = [{ required: true, message: 'Segment is required', trigger: ['blur', 'change'] }]
      }
      return currentRules
    },
  },
  watch: {
    userLoading: {
      handler(val) {
        if (this.isOnlyView) {
          return
        }
        if (!val) {
          if (!this.editPermission && !this.createPermission) {
            this.$router.push(`/fortune-wheel/${this.$route?.params?.id}/`)
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    getPopupContainer(trigger) {
      return trigger.parentElement
    },
    disableDatesBeforeToday(startValue) {
      const endValue = this.form.ends_at
      if (!startValue || !endValue) {
        return startValue && startValue < this.$moment().add(-1, 'day').endOf('day')
      }
      return startValue.valueOf() > endValue.valueOf()
    },
    disabledEndDate(endValue) {
      const startValue = this.form.starts_at
      if (!endValue || !startValue) {
        return false
      }
      return startValue.valueOf() >= endValue.valueOf()
    },
    toggleAppModal(status) {
      if (this.loading) {
        return
      }
      this.appsModal = status
    },
    toggleLanguageModal(status) {
      if (this.loading) {
        return
      }
      this.languageModal = status
      if (!status) {
        this.selectedLanguage = null
      }
    },
    changeSelectedApps(apps) {
      this.toggleAppModal(false)
      this.form.applications = apps
      this.modalAppsData = apps
      this.validateApps()
    },
    saveLanguage(language) {
      if (this.form.languages.length) {
        const langIdx = this.form.languages.findIndex(lang => lang.language_id === language.language_id)
        if (langIdx >= 0) {
          this.form.languages.splice(langIdx, 1, language)
          return
        }
      } else {
        language.is_default = true
      }
      this.form.languages.push(language)
    },
    changeDefaultLanguage(langIndex) {
      this.form.languages = this.form.languages.map(lang => {
        return { ...lang, is_default: false }
      })
      this.form.languages[langIndex].is_default = true
    },
    editLanguage(lang) {
      this.selectedLanguage = lang
      this.toggleLanguageModal(true)
    },
    deleteLanguage(lang, langIdx) {
      this.form.languages.splice(langIdx, 1)
      if (lang.is_default && this.form.languages.length) {
        this.form.languages[0].is_default = true
      }
    },
    getLanguageName(language_id) {
      return this.languageOptions.find(lang => lang.value === language_id)?.name || ''
    },
    async validateApps() {
      const isValid = await new Promise((resolve) => {
        this.$refs.form.validateField('applications', resolve)
      })
      return isValid
    },
    getFormData() {
      const data = {
        name: this.form.name,
        starts_at: this.form.starts_at ? this.form.starts_at.format('YYYY-MM-DD HH:mm:ss') : null,
        ends_at: this.form.ends_at ? this.form.ends_at.format('YYYY-MM-DD HH:mm:ss') : null,
        applications: this.form.applications,
        languages: this.form.languages,
        show_frequency: this.form.show_frequency,
        display_type: this.form.display_type,
      }
      if (this.currentWheel) {
        data.status = this.currentWheel.status
      }
      if (this.form.is_auth_only) {
        data.is_auth_only = this.form.is_auth_only
        data.segment_id = this.form.segment_id
      } else {
        data.is_auth_only = this.form.is_auth_only
      }
      return data
    },
    async save() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const data = this.getFormData()
          try {
            this.loading = true
            let response
            if (this.currentWheelId) {
              const url = `${wheelApiUrl}/admin/wheels/${this.currentWheelId}`
              response = await apiClient.patch(url, data)
            } else {
              const url = `${wheelApiUrl}/admin/wheels`
              response = await apiClient.post(url, data)
            }
            if (response?.data?.success) {
              const wheelId = response.data.data.id
              this.$router.push(`/fortune-wheel/${wheelId}`)
            } else {
              getErrorMessage(response?.data)
            }
          } finally {
            this.loading = false
          }
        } else {
          return false
        }
      })
      return false
    },
    setAvailableOptions({ applications, languages }) {
      this.applicationOptions = applications
      this.languageOptions = languages.map(lang => {
        return { name: lang.name, value: lang.language_id }
      })
    },
    setWheelData(data) {
      this.currentWheel = data
      this.form.name = data.name
      this.form.starts_at = data.starts_at ? this.$moment(data.starts_at) : undefined
      this.form.ends_at = data.ends_at ? this.$moment(data.ends_at) : undefined
      this.form.is_auth_only = data.is_auth_only
      this.form.segment_id = data.segment ? data.segment.id : undefined
      this.form.applications = data.applications
      this.modalAppsData = data.applications
      this.form.languages = data.languages
      this.form.show_frequency = data.show_frequency
      this.form.display_type = data.display_type
    },
    async deleteWheel() {
      try {
        this.loading = true

        const url = `${wheelApiUrl}/admin/wheels/${this.currentWheelId}`
        const response = await apiClient.delete(url)
        if (response?.data?.success) {
          this.$notification.success({
            message: 'Successfully',
            description: response?.data?.message || '',
          })
          this.$router.push('/fortune-wheel')
        } else {
          getErrorMessage(response?.data)
        }
      } catch (e) {
        this.$notification.success({
          message: e?.message ? e.message : 'Error',
        })
      } finally {
        this.loading = false
      }
    },
    async getWheelParams() {
      try {
        this.currentWheelId = this.$route.params.id
        const url = `${wheelApiUrl}/admin/wheels/${this.currentWheelId}`

        const response = await apiClient.get(url)
        if (response?.data?.success) {
          if (response.data.data.status === 'active' && !this.isOnlyView) {
            this.$router.push(`/fortune-wheel/${this.currentWheelId}`)
          }
          if (this.isOnlyView) {
            this.setAvailableOptions({ applications: response?.data?.data?.applications || [], languages: response?.data?.data?.languages || [] })
          }
          this.setWheelData(response.data.data)
        }
      } catch (e) {
        this.$notification.success({
          message: e?.message ? e.message : 'Error',
        })
      }
    },
    async getParams() {
      try {
        const url = `${wheelApiUrl}/admin/wheels/create`
        const response = await apiClient.get(url)
        if (response?.data?.success) {
          this.segmentsOptions = response.data.options.segments.map(segment => {
            return { name: segment.name, value: segment.id }
          })
          if (!this.isOnlyView) {
            this.applicationOptions = response?.data?.options?.applications || []
            this.languageOptions = response.data.options.languages.map(lang => {
              return { name: lang.name, value: lang.id }
            })
          }
        }
      } catch (e) {
        this.$notification.success({
          message: e?.message ? e.message : 'Error',
        })
      }
    },
  },
  async created() {
    await this.$nextTick(() => {})
    const requests = [this.getParams()]
    if (this.$route?.params?.id) {
      requests.push(this.getWheelParams())
    }
    try {
      this.loading = true
      await Promise.all(requests)
    } finally {
      this.loading = false
    }
  },
}
</script>

<style lang="scss">
.fortune-wheel-form {
  &__languages {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  &__language {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 38px;
    border: 1px solid #e4e9f0;
    border-radius: 4px;
    max-width: 400px;
    padding: 0 10px;
    &:hover {
      border-color: #40a9ff;
    }
    & > div {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    &__set-default {
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .add-link {
    color: #007bff;
    font-weight: 400;
  }
  .hover-date-picker {
    & input {
      &:hover {
        cursor: pointer !important;
      }
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance:textfield;
}
</style>
